.lessoncontent {
    background-color: darkgoldenrod;
    padding-bottom: 30px;
    border-radius: 45px;
}

.modulesubtitle {
    background-color: darkkhaki;
    border-radius: 25px;
    margin-left: 400px;
    margin-right: 400px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.lessontext {
    padding-left: 30px;
    padding-right: 30px;
    font-size: 13pt;
}

.palatographyimg {
    width: 300px;
    height: 200px;
}

.emaimg {
    width: 200px;
    height: 260px;
}

.subtwo {
    padding-left: 60px;
}

.subthree {
    padding-left: 80px;
}

.subfour {
    padding-left: 100px;
}

.lessonimg {
    padding-left: 150px;
    padding-bottom: 20px;
    padding-top: 20px;
}

.lessonimginline {
    padding-bottom: 15px;
}