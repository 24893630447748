.profprofile {
    padding: 20px;
}

.emailcontact {
    font-size: 40pt;
}

.phonecontact {
    font-size: 24pt;
}