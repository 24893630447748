.exercisetitle {
    font-size: 18pt;
    background-color: turquoise;
    margin-bottom: 20px;
}

.exercisequestion {
    font-size: 14pt;
    color: black;
}

.exercisesubquestion {
    font-size: 12pt;
    padding: 15px;
}

.exerciseone {
    background-color: darkturquoise;
}

.exercisetwo {
    background-color: rgb(3, 194, 198);
}

.exercisethree {
    background-color: rgb(2, 173, 176);
}

.exercise {
    border: 2.5px black solid;
}

.exerciseinput {
    border: 2px gray solid;
    margin-bottom: 5px;
    margin-top: 5px;
    background-color: lightgreen;
    padding: 9px;
}

.exercisesubmit {
    flex: 1;
    margin-left: 50px;
    margin-right: 50px;
    margin-bottom: 30px;
    background-color: darkred;
}

.exercisesubmitcol {
    display: flex;
}