.learnobjheader {
    font-weight: bold;
}

.modsummary {
    padding-left: 60px;
    padding-right: 60px;
    margin: 20px;
    font-size: 18pt;
}

.mainbuttons {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
}

.mainbutton {
    flex: 1;
    border: 2px solid black;
    margin-left: 10px;
    margin-right: 10px;
}

.tablistitem {
    background-color: darkorange;
}

.tabgroup {
    
}

.titleandsummary {
    background-color: burlywood;
}

.learningobjectives {
    background-color: chartreuse;
}

.learnobj {
    background-color: sienna;
    color: white;
    font-size: 14pt;
}



.moduletitle {
    font-size: 26pt;
}