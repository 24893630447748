.navlink {
    color: white;
    font-size: 24pt;
    border: 2px yellow solid;
    padding: 8px;
}

.firstnavlink {
    margin-left: 150px;
}

.langwiztitle {
    font-size: 30pt;
    color: yellow
}

.navlinkdropdown {
    font-size: 26pt;
    margin-left: 40px;
    color: orange;
    font-weight: bold;
}

.navlinkdropdownlink {
    
}

.headerpic {
    flex: 1;
}

.headerpicdiv {
    display: flex;
}