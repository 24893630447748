.cardcontent {
    margin: 5px;
    background-color: blueviolet;
    padding: 20px;
    color: white;
    font-weight: bold;
    border-radius: 25px;
    border: 5px solid orange;
}

.result {
    background-color: black;
    color: white;
    margin-top: 15px;
}

.tempresult {
    color: lightgray;
}

.appytitle {
    background-color: rgb(170, 129, 209);
    color: white;
    font-size: 24pt;
}

.appysubmit {
    background-color: lightgray;
    color: black;
    margin-top: 15px;
    font-size: 11pt;
    padding: 0;
}

.appyformrow {
    padding-top: 10px;
    padding-bottom: 10px;
}