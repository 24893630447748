.truefalse {
    background-color: papayawhip;
}

.multiplechoice {
    background-color: peachpuff;
}

.quiztitle {
    background-color: yellow;
}

.quizsectitle {
    background-color: beige;
}